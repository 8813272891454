/**
 * Header JS
 */

'use strict';

import throttle from 'lodash.throttle';
import { TweenMax, TweenLite } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { scrollTop, getPosition } from '../helpers/helpers';
import bookBM from '../json/Book-Now.json';
import logoBM from '../json/Willamette-Jetboat-Excursions.json';
import CustomBodymovin from './bodymovin';

export default class Header {

	constructor() {
		this.body = document.querySelector('body');
		this.header = document.querySelector('.header');
		// this.logo = document.querySelector('.mobile-nav__logo');
		this.pagetop = document.querySelector('.btn-pagetop');
		// this.navItems = [...document.querySelectorAll('.data-nav-target a')];
		this.mainNavItems = [...document.querySelectorAll('.menu-item a')];
		this.hamburger = document.querySelector('.header__hamburger');
		this.bookLink = document.querySelector('.main-menu-books a');
		this.bmBook = document.getElementById('badges--book');
		this.logo = document.getElementById('logo');
		this.mobileNavIsOpen = false;
		this.isSlimHeader = false;
		this.isRemoveHeader = false;
		this.stp = 0;
		// if (this.bmBook) this.setupLogoBodyMovin();
		this.events();
		this.scrollHandle();

		this.urls = {
			'logo': 'Willamette-Jetboat-Excursions.json',
			'logo-mobile-nav': 'Willamette-Jetboat-Excursions.json',
			'badges--book': 'Book-Now.json'
		}
		this.init()

	}


	init() {
		Object.entries(this.urls).forEach(([item, file]) => {
			let url = `${window.location.origin}/wp-content/themes/willamette-jetboat/assets/js/json/${file}`;
			// console.log(url)
			this.setting(url, item);
		});
	}


	setting(url, item) {

		fetch(url)
			.then(
				response => {
					if (response.status !== 200) {
						console.log('Looks like there was a problem. Status Code: ' +
							response.status);
						return;
					}
					response.json().then(data => {
						this.LottieSet(data, url, item);
					});
				}
			)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}



	/**
	 * Event listeners
	 */
	events() {
		this.scrollHandle = throttle(this.scrollHandle, 16).bind(this);
		window.addEventListener('scroll', this.scrollHandle);

		this.hamburger.addEventListener('click', this.hamburgerHandle.bind(this));

		if (this.mainNavItems) {
			this.mainNavItems.forEach(item => {
				item.addEventListener('click', e => {
					this.closeMobileNav();
				});
			});
		}
		// this.logo.addEventListener('click', e => {
		// 	this.closeMobileNav();
		// });
	}

	hamburgerHandle() {
		this.mobileNavIsOpen ? this.closeMobileNav() : this.openMobileNav();
	}

	openMobileNav() {
		this.body.classList.add('mobile-nav-open');
		this.mobileNavIsOpen = true;
	}

	closeMobileNav() {
		this.body.classList.remove('mobile-nav-open');
		this.mobileNavIsOpen = false;
	}

	// Make header slimmer
	slimHeader() {
		if (!this.isSlimHeader) {
			this.header.classList.add('header--slim');
			this.isSlimHeader = true;
		}
	}

	// Reset header to default state
	defaultHeader() {
		if (this.isSlimHeader) {
			this.header.classList.remove('header--slim');
			this.isSlimHeader = false;
		}
		if (this.isRemoveHeader) {
			this.header.classList.remove('header--remove');
			this.isRemoveHeader = false;
		}
	}
	// Make header remove
	removeHeader() {
		if (!this.isRemoveHeader) {
			this.header.classList.add('header--remove');
			this.isRemoveHeader = true;
		}
	}

	scrollHandle() {
		const st = scrollTop();

		if (st > this.stp) {
			//down
			this.removeHeader();
		} else {
			//up
			this.defaultHeader();
		}

		st > 10 ? this.slimHeader() : this.defaultHeader();

		this.stp = scrollTop();

		// Hide Page Top Buttom
		if (st > window.outerHeight) {
			this.pagetop.classList.add('show');
		} else {
			this.pagetop.classList.remove('show');
		}
	}

	// setupLogoBodyMovin() {
	// 	this.bmBook.classList.remove('init');
	// 	this.logoBmObj = new CustomBodymovin({
	// 		elm: this.bmBook,
	// 		data: bookBM
	// 	});
	// 	this.logoBmObj.goToAndStop(2000);
	// 	this.bookLink.addEventListener('mouseenter', e =>
	// 		this.bmHover(e)
	// 	);
	// 	this.logoBM = new CustomBodymovin({
	// 		elm: this.logo,
	// 		data: logoBM
	// 	});
	// 	this.logoBM.play();
	// }

	LottieSet(data, url, item) {
		this.bmBook.classList.remove('init');

		let badge = document.getElementById(item);

		if (item == 'logo') {
			let obj = lottie.loadAnimation({
				container: badge,
				renderer: 'svg',
				loop: false,
				autoplay: false,
				path: url,
				rendererSettings: {
					progressiveLoad: false
				},
				animationData: data
			});

			obj.play();
		} else if (item == 'logo-mobile-nav') {
			let obj = lottie.loadAnimation({
				container: badge,
				renderer: 'svg',
				loop: false,
				autoplay: false,
				path: url,
				rendererSettings: {
					progressiveLoad: false
				},
				animationData: data
			});

			obj.play();
		} else {
			this.obj = lottie.loadAnimation({
				container: badge,
				renderer: 'svg',
				loop: false,
				autoplay: false,
				path: url,
				rendererSettings: {
					progressiveLoad: false
				},
				animationData: data
			});

			this.obj.goToAndStop(2000);
			this.bookLink.addEventListener('mouseenter', e =>
				this.bmHover(e)
			);

			this.obj.play();
		}

	}

	bmHover(e) {
		this.obj.goToAndStop(0);
		this.obj.play();
	}
}

// https://staging.willamette-jetboat.com/wp-content/themes/willamette-jetboat/assets/js/json/Book-Now.json