/**
 * Excursion JS
 */

'use strict';

import 'whatwg-fetch';
import {
    TweenMax,
    TweenLite,
    TimelineMax
} from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ScrollReveal from 'scrollreveal';
import CustomBodymovin from './bodymovin';

export default class Excursion {

    constructor() {
        this.excursionJsons = [...document.querySelectorAll('[data-json-animation]')];
        this.slugNames = ['quick-scenic', 'shipyards', 'columbia', 'willamette-falls', 'lunch'];
        this.BMDatas = [];
        this.sr = ScrollReveal({
            duration: 800,
            scale: 0.98,
            distance: '80px',
            opacity: 0
        });
        this.init();
    }

    init() {

        this.slugNames.forEach((item, i) => {
            let url = this.excursionJsons[i].dataset.jsonAnimation;
            this.setting(url, item);
        });

        // if (window.innerWidth > 700) {
        //     document.getElementById("overlay-img").src = "../wp-content/themes/willamette-jetboat/assets/img/excursions/hamlyn_waterbanner_desktop.png";
        // } else {
        //     document.getElementById("overlay-img").src = "../wp-content/themes/willamette-jetboat/assets/img/excursions/hamlyn_waterbanner_mobile.png";
        // }

        // window.addEventListener('resize', function (event) {
        //     if (window.innerWidth > 700) {
        //         document.getElementById("overlay-img").src = "../wp-content/themes/willamette-jetboat/assets/img/excursions/hamlyn_waterbanner_desktop.png";
        //     } else {
        //         document.getElementById("overlay-img").src = "../wp-content/themes/willamette-jetboat/assets/img/excursions/hamlyn_waterbanner_mobile.png";
        //     }
        // }, true);

    }

    setting(url, item) {

        fetch(url)
            .then(
                response => {
                    if (response.status !== 200) {
                        console.log('Looks like there was a problem. Status Code: ' +
                            response.status);
                        return;
                    }
                    response.json().then(data => {
                        this.LottieSet(data, item, url);
                        // if (item == 'river-run') {
                        //     this.LottieSet(data, item, url);
                        // } else {
                        //     this.BMSet(data, item);
                        // }
                    });
                }
            )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }

    BMSet(data, item) {
        let badge = document.getElementById('badges--' + item);

        let obj = new CustomBodymovin({
            elm: badge,
            loop: false,
            data: data,
        });


        this.sr.reveal('.' + item, {
            afterReveal: (domEl) => {
                obj.play();
            }
        });
    }

    LottieSet(data, item, url) {
        let badge = document.getElementById('badges--' + item);

        let obj = lottie.loadAnimation({
            container: badge,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: url,
            rendererSettings: {
                progressiveLoad: false
            },
            animationData: this.data
        });


        this.sr.reveal('.' + item, {
            afterReveal: (domEl) => {
                obj.play();
            }
        });
    }

}
