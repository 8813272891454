/* FLEXIBLE ACCORDION */
/* ----------------------------------------- */


export default class AccordionHero {
    constructor() {
        this.component = document.getElementById("accordion-container");
        this.tabs = document.querySelectorAll(".tab-label");
        this.tabsContainer = document.querySelector(".tabs-container");
        this.heroContainer = document.querySelector(".hero-content-container");
        this.tabWidth = document.querySelector('.tab-label[data-tab="tab-1"]').offsetWidth;
        this.heroSpacer = document.querySelector(".hero-spacer");
        this.multiplier = document.querySelectorAll(".tab-label").length;
        this.getWidth = this.tabWidth * this.multiplier;
        this.isHeroRaised = false;
        this.init();
        this.registerEvents();
    }

    defaultHero() {
        // console.log('test');
        if (this.isHeroRaised) {
            this.component.classList.remove("hero--raised");
            this.isHeroRaised = false;
        } else {
            return;
        }
    }

    raiseHero() {
        if (!this.isHeroRaised) {
            this.component.classList.add("hero--raised");
            this.isHeroRaised = true;
        } else {
            return;
        }
    }

    init() {
        this.isMobile();
        this.applyWidth(this.heroSpacer, this.getWidth);
    }

    registerEvents() {
        window.addEventListener("scroll", function () {
            function scrollTop() {
                return document.documentElement.scrollTop || window.pageYOffset;
            }
            const st = scrollTop();
            st > 10 ? this.raiseHero : this.defaultHero;
        });
        Array.from(this.tabs).forEach((tab) => {
            tab.addEventListener("click", (e) => {
                const tabData = e.target.getAttribute("data-tab");
                const curr = document.querySelectorAll(`[data-tab='${tabData}'`);
                const body = document.querySelector(`.tab-body[data-tab='${tabData}']`);
                const heroWidth = document.querySelector(".hero-content-container")
                    .offsetWidth;

                if (this.isMobile()) {
                    this.scrollToSpot(0);
                } else {
                    this.scrollToSpot(50);
                }

                document.querySelectorAll("[data-tab]").forEach((item) => {
                    if (item.getAttribute("data-tab") !== tabData) {
                        item.classList.remove("open");
                    }
                });

                document.querySelectorAll(".tab-body").forEach((item) => {
                    if (!this.isMobile()) {
                        item.style.width = `${this.tabWidth}px`;
                    }
                    item.classList.add("closing");
                    setTimeout(() => {
                        item.classList.remove("closing");
                    }, 1000);
                });

                curr.forEach((item) => {
                    item.classList.toggle("open");
                    this.tabsContainer.classList.add("open");
                    this.heroContainer.classList.add("open");
                });
                if (!this.isMobile()) {
                    body.style.width = `${heroWidth + this.tabWidth}px`;
                }
            });
        });
    }

    isMobile() {
        let isMobile = window.matchMedia("only screen and (max-width: 760px)")
            .matches;

        if (isMobile) {
            this.component.classList.add("mobile");
            return true;
        }
    }


    applyWidth(element, width) {
        element.style.width = `${width}px`;
    }

    scrollToSpot(x) {
        window.scrollTo({
            top: x,
            behavior: "smooth"
        });
    }
}