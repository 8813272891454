/**
 * Hover Silde Show JS
 */

'use strict';

import { TweenMax, TweenLite, TimelineMax } from 'gsap';
import { viewport } from '../helpers/helpers';
export default class HoverSildeShow {

    constructor(mode = null, spmode = true) {
        this.hoverItems = [...document.querySelectorAll('[data-hss-slug]')];
        this.hoverImgItems = [...document.querySelectorAll('[data-hss-image]')];
        this.listboxInner = null;
        this.preListHover = null;
        this.preImgHover = null;
        this.currentHover = null;
        this.hoverImgTl = null;
        this.hoverPreImgTl = null;
        if (!this.hoverItems) return false;
        // fade mode
        this.mode = mode;
        this.spmode = spmode;
        this.onresize = this._onResize.bind(this);

        switch (this.mode) {
        case "fade":
            window.addEventListener('resize', this.onresize);
            break;
		
        default:
            break;
        }
        if (this.spmode) {
            this.showSlide(this.hoverItems[0]);
        } else {
            if (viewport().width >= 768) {
                this.showSlide(this.hoverItems[0]);
            }
        }
    }

    allremove() {
        window.removeEventListener('resize', this.onresize);
    }

    _onResize() {
        if (!this.listboxInner) return false;
        let elementHeight = this.listboxInner.clientHeight;
        let listboxImages = document.querySelector('.listbox-images');
        listboxImages.style.height = `${elementHeight}px`;
    }

    fadeSetting() {
        let listboxImages = document.querySelector('.listbox-images');
        let listboxImagesWrappers = [...document.querySelectorAll('.listbox-images-wrapper')];
        let maxHeight = 0;
        listboxImagesWrappers.forEach(item => {
            let listboxInner = item.querySelector('.listbox-inner');
            let wrapHeight = listboxInner.clientHeight;
            maxHeight = (maxHeight < wrapHeight) ? wrapHeight : maxHeight;
        });
        listboxImages.style.height = `${maxHeight}px`;
    }

    /**
	 * init
	 */
    init() {
        this.events();
    }

    /**
	 * Event listeners
	 */
    events() {
        this.hoverItems.forEach(item => {
            let itema = item.querySelector('a');
            itema.addEventListener('mouseenter', e => 
                this.showSlide(e.currentTarget.parentElement)
            );
            if (this.mode === "fade") {
                itema.addEventListener('click', e => {
                    e.preventDefault();
                    e.stopPropagation();
                });
            }
        });
    }

    setHeight(element) {
        this.listboxInner = element.querySelector('.listbox-inner');
        let elementHeight = this.listboxInner.clientHeight;
        let listboxImages = document.querySelector('.listbox-images');
        listboxImages.style.height = `${elementHeight}px`;
    }

    showSlide(current) {
        let currentli = current;
        if (this.preListHover == current) return false;
        let slug = currentli.dataset.hssSlug;
        let element = document.querySelector(`[data-hss-image="${slug}"]`);

        switch (this.mode) {
        case "fade":
            this.setHeight(element);
            // this.listboxInner = element.querySelector('.listbox-inner');
            // let elementHeight = this.listboxInner.clientHeight;
            // let listboxImages = document.querySelector('.listbox-images');
            // listboxImages.style.height = `${elementHeight}px`;
            break;
        default:
            break;
        }


        // if (TweenMax.isTweening(element)) {
        // 	TweenMax.killTweensOf(element);
        // };
        // if (TweenMax.isTweening(this.preImgHover)) {
        // 	TweenMax.killTweensOf(this.preImgHover);
        // 	this.hoverPreImgTl.kill(true, true, true, true);
        // };

        if (this.preListHover) {
            TweenLite.set(this.preImgHover, { clearProps: "all" });
            this.preImgHover.classList.remove('list-news-image--active');
            this.preListHover.classList.remove('list-news-item--active');
        }
        currentli.classList.add('list-news-item--active');
        element.classList.add('list-news-image--active');

        if (element) {
            let hoverImgTl = new TimelineMax();

            switch (this.mode) {
            case "fade":
                hoverImgTl
                    .fromTo(element, 0.6, { autoAlpha: 0 }, { autoAlpha: 1, ease: Sine.easeInOut });
                break;
            default:
                hoverImgTl
                    .fromTo(element, 0.6, { x: "0%" }, { x: "-100%", ease: Sine.easeInOut });
                break;
            }
			
            this.hoverPreImgTl = hoverImgTl;
            if (this.preImgHover) {
                const hoverPreImgTl = new TimelineMax();
                switch (this.mode) {
                case "fade":
                    hoverPreImgTl
                        .fromTo(this.preImgHover, 0.9, { autoAlpha: 1 }, { autoAlpha: 0, ease: Sine.easeInOut });
                    break;
                default:
                    hoverPreImgTl
                        .fromTo(this.preImgHover, 0.9, { x: "-100%" }, { x: "-201%", ease: Sine.easeInOut });
                    break;
                }
            }
            this.preListHover = currentli;
            this.preImgHover = element;
        }
    }



}
