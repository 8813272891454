/**
 * getintouch JS
 */

'use strict';


export default class Getintouch {

	constructor() {
		this.btnClose = document.querySelector('.btn-close');
		this.btnSubmit = document.querySelector('.wpcf7-submit');
		this.inputTexts = [...document.querySelectorAll('.wpcf7-text,.wpcf7-textarea')];
		this.chkMaxNum = this.inputTexts.length;
		this.checkFormArray = [];
		this.events();
		this.initMap();
		this.checkFormID = null;
	}

	/**
	 * Event listeners
	 */
	events() {

		if (this.inputTexts) {
			this.inputTexts.forEach(item => {
				item.addEventListener('blur', e => {
					e.preventDefault();
					if (item.value) {
						this.checkFormArray.push(item.name);
					} else {
						this.checkFormArray.some((v, i) => {
							if (v == item.name) this.checkFormArray.splice(i, 1);
						}
						);
					}

					let sort = this.checkFormArray.filter(function (x, i, self) {
						return self.indexOf(x) === i;
					});

					this.checkFormArray = sort;
					if (this.checkFormArray.length == this.chkMaxNum) {
						this.btnSubmit.classList.add('submitReady');
					} else {
						this.btnSubmit.classList.remove('submitReady');
					}
				});
			});
		}
	}

	initMap() {
		var myLatLng = { lat: 45.509791, lng: -122.66628 };
		var map = new google.maps.Map(document.getElementById('map'), {
			center: myLatLng,
			zoom: 15,
			styles:
				[
					{
						"featureType": "all",
						"elementType": "geometry",
						"stylers": [
							{
								"visibility": "on"
							}
						]
					},
					{
						"featureType": "all",
						"elementType": "labels",
						"stylers": [
							{
								"visibility": "simplified"
							},
							{
								"saturation": "7"
							},
							{
								"gamma": "1.37"
							},
							{
								"weight": "0.01"
							}
						]
					},
					{
						"featureType": "all",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"visibility": "on"
							}
						]
					},
					{
						"featureType": "all",
						"elementType": "labels.text.stroke",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "all",
						"elementType": "labels.icon",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "administrative",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#444444"
							}
						]
					},
					{
						"featureType": "landscape",
						"elementType": "all",
						"stylers": [
							{
								"color": "#f2f2f2"
							}
						]
					},
					{
						"featureType": "poi",
						"elementType": "all",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "all",
						"stylers": [
							{
								"saturation": -100
							},
							{
								"lightness": 45
							}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "all",
						"stylers": [
							{
								"visibility": "simplified"
							}
						]
					},
					{
						"featureType": "road.arterial",
						"elementType": "labels.icon",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "transit",
						"elementType": "all",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "water",
						"elementType": "all",
						"stylers": [
							{
								"color": "#2B62A2"
							},
							{
								"visibility": "on"
							}
						]
					}
				]

		});
		var marker = new google.maps.Marker({
			position: myLatLng,
			map: map,
			title: 'Willamette!'
		});
	}

}
