/**
 * Pilots JS
 */

'use strict';

import Swiper from 'swiper';
import { TweenMax, TweenLite, Power3 } from 'gsap';

export default class Pilots {

	constructor() {
		this.pilotElement = document.querySelector('.pilots__element--sc');
		this.pilotElements = document.querySelector('.pilots__element-image');
		this.init();
	}

	init() {

		var swiperDesc = new Swiper(this.pilotElement, {
			speed: 1000,
			effect: 'fade',
			slidesPerView: 'auto',
			fadeEffect: {
				crossFade: true
			},
		});

		var slider = new Swiper(this.pilotElements, {
			slidesPerView: 3,
			spaceBetween: 30,
			centeredSlides: true,
			mousewheel: {
				invert: true,
			},
			slidesPerView: 'auto',
			slideToClickedSlide: true,
		});

		swiperDesc.controller.control = slider;
		slider.controller.control = swiperDesc;

	}

}
