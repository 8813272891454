/**
 * returns true if touch device
 */
export function isTouchDevice() {
	return 'ontouchstart' in window || navigator.maxTouchPoints;
}

/**
 * Get accurate viewport width and height
 * Example: viewport().width
 */
export function viewport() {
  var e = window, a = "inner";
  if (!( "innerWidth" in window )) {
    a = "client";
    e = document.documentElement || document.body;
  }
  return { width : e[ a+"Width" ] , height : e[ a+"Height" ] }
}

/**
 * returns the absolute position of an element regardless of position/float issues
 * @param {HTMLElement} el - element to return position for 
 * @returns {object} { x: num, y: num }
 */
export function getPosition(el) {

  let x = 0;
  let y = 0;

  do {
    x += el.offsetLeft || 0;
    y += el.offsetTop || 0;
    el = el.offsetParent;
  } while (el);

  return { x: parseInt(x, 10), y: parseInt(y, 10) };
}

/**
 * Get the height of the entire page
 * Compatible with most (if not all) browsers
 */

export function pageHeight() {
  let body = document.body;
  let html = document.documentElement;
  return Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
}

/**
 * Get the current scrollTop / pageYOffset value
 * Compatible with most (if not all) browsers
 */
export function scrollTop() {
  return document.documentElement.scrollTop || window.pageYOffset;
}
/**
 * lead zero padding
 */
Number.prototype.pad = function (size) {
  var s = String(this);
  while (s.length < (size || 2)) { s = "0" + s; }
  return s;
}

