/**
 * PageTransition JS
 */

'use strict';

import Barba from 'barba.js';
import {
    TweenLite,
    TimelineLite
} from 'gsap';
import Home from './home';
import Popup2021 from './popup2021';
import Excursion from './excursion';
// import SideContent from './sideContent';
import Pilots from './pilots';
import Accordion from './accordion';
import AccordionHero from './accordionHero';
import LinkTarget from './linkTarget';
import HoverSildeShow from './hoversildeshow';
import Getintouch from './getintouch';
import {
    scrollTop,
    getPosition
} from '../helpers/helpers';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

export default class pageTransition {

    constructor() {
        this.body = document.querySelector('body');
    }

    getParam(url) {
        return url.replace('?', '');
    }

    /**
   * init listeners
   */
    init() {
        const self = this;
        Barba.Pjax.init();
        Barba.Prefetch.init();

        // Google Analytics
        Barba.Dispatcher.on('initStateChange', function () {
            if (typeof ga === 'function') {
                ga('send', 'pageview', location.pathname);
            }
        });

        //---------------------------------------//
        //       When Transition Completed       //
        //---------------------------------------//
        Barba.Dispatcher.on('transitionCompleted', function (currentStatus) {
            // Prevent Reloading When User click a link that is a same page as a current page
            const links = document.querySelectorAll('a[href]');
            const cbk = function (e) {
                if (e.currentTarget.href === window.location.href) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            };
            for (let i = 0; i < links.length; i++) {
                links[i].addEventListener('click', cbk);
            }
        });
        Barba.Dispatcher.on('newPageReady', function (newStatus, oldStatus, container, html) {
            let htmlDiv = document.createElement("html");
            htmlDiv.innerHTML = html;
            let newNav = [...htmlDiv.querySelectorAll('[data-barba-update]')];
            let cNav = [...document.querySelectorAll('[data-barba-update]')];

            for (let i = 0; i < newNav.length; i++) {
                cNav[i].parentNode.replaceChild(newNav[i], cNav[i]);
            }


            //body class
            let newBody = htmlDiv.querySelector('body');
            let oldBody = document.querySelector('body');
            const elementClasses = [...newBody.classList];
            oldBody.className = "";
            elementClasses.forEach(className => {
                oldBody.className += className + ' ';
            });


        });

        //---------------------------------------//
        //               Basic                   //
        //---------------------------------------//
        const Basic = Barba.BaseView.extend({
            namespace: "basic",
            Nav: null,
            onEnter: function () {
                this._construct();
            },
            onEnterCompleted: function () {
                this.start();
            },
            _construct: function () {
                this.destroy();
            },
            destroy: function () {
                this.Nav = null;
            },
            start: function () {
                this.accordions = [...document.querySelectorAll('.accordion')];
                if (this.accordions) {
                    new Accordion(this.accordions);
                }
                this.pilots = document.querySelector('.pilots');
                if (this.pilots) {
                    new Pilots();
                }
            }
        });
        Basic.init();

        //---------------------------------------//
        //               News single            //
        //---------------------------------------//
        const NewsSingle = Barba.BaseView.extend({
            namespace: "news-single",
            onEnter: function () {
                this._construct();
            },
            onEnterCompleted: function () {
                this.start();
            },
            _construct: function () {
                this.destroy();
            },
            destroy: function () { },
            start: function () { }
        });

        //---------------------------------------//
        //               Home                    //
        //---------------------------------------//
        const HomePage = Barba.BaseView.extend({
            namespace: "home",
            home: null,
            onEnter: function () {
                this._construct();
            },
            onEnterCompleted: function () {
                this.start();
            },
            _construct: function () {
                this.destroy();
            },
            onLeaveCompleted: function () {
                this.home = null;
            },
            destroy: function () { },
            start: function () {
                this.home = Home.getInstance();
                this.home.init();
                new Popup2021();
            }
        });
        HomePage.init();

        //---------------------------------------//
        //              Excursion                //
        //---------------------------------------//
        const ExcursionPage = Barba.BaseView.extend({
            namespace: "excursion",
            excursion: null,
            onEnter: function () {
                this._construct();
            },
            onEnterCompleted: function () {
                this.start();
            },
            _construct: function () {
                this.destroy();
            },
            destroy: function () {
                this.excursion = null;
            },
            start: function () {
                new Excursion();
            }
        });
        ExcursionPage.init();

        //---------------------------------------//
        //             River Lodge               //
        //---------------------------------------//
        const RiverLodge = Barba.BaseView.extend({
            namespace: "river-lodge",
            hoverSildeShow: null,
            onEnter: function () {
                this._construct();
            },
            onEnterCompleted: function () {
                this.start();
            },
            _construct: function () {
                this.destroy();
            },
            destroy: function () {
                this.hoverSildeShow = null;
            },
            start: function () {
                this.hoverSildeShow = new HoverSildeShow("fade");
                this.hoverSildeShow.init();
                new AccordionHero;
            },
            onLeaveCompleted: function () {
                if (this.hoverSildeShow) this.hoverSildeShow.allremove();
            }
        });
        RiverLodge.init();


        //---------------------------------------//
        //            Get In Touch               //
        //---------------------------------------//
        const GetInTouch = Barba.BaseView.extend({
            namespace: "getintouch",
            onEnter: function () {
                this._construct();
            },
            onEnterCompleted: function () {
                this.start();
            },
            _construct: function () {
                this.destroy();
            },
            destroy: function () { },
            start: function () {
                new Getintouch();
            }
        });
        GetInTouch.init();

        //---------------------------------------//
        //            Page Transition            //
        //---------------------------------------//
        const PageAnimation = Barba.BaseTransition.extend({
            start: function () {
                Promise
                    .all([this.newContainerLoading])
                    .then(this.pageCheck.bind(this))
                    .then(this.fadeInOut.bind(this));
            },

            fadeInOut: function () {
                const _this = this;
                const tl = new TimelineLite();
                const targetPosition = self.getParam(location.search);

                return tl
                    .set(this.newContainer, {
                        autoAlpha: 0
                    })
                    .to(this.oldContainer, 0.6, {
                        autoAlpha: 0
                    })
                    .set(this.oldContainer, {
                        display: 'none'
                    })
                    .to(window, 0.01, {
                        scrollTo: '.wrapper'
                    })
                    .add('new')
                    .to(this.newContainer, 0.5, {
                        autoAlpha: 1,
                        onComplete: function () {
                            TweenLite.set([_this.newContainer, 'header'], {
                                clearProps: 'all'
                            });
                            _this.done();
                            if (targetPosition) {
                                const section = _this.newContainer.querySelector(`[data-section="${targetPosition}"]`);
                                if (section) {
                                    TweenLite.to(window, 1.5, {
                                        ease: Power3.easeInOut,
                                        scrollTo: {
                                            y: getPosition(section).y,
                                            autoKill: false
                                        }
                                    });
                                }
                            }
                        }
                    }, 'new');

            },

            pageCheck: function () {
                const oldPage = this.oldContainer.dataset.namespace;
                const page = this.newContainer.dataset.namespace;
                const body = document.body;

                if (oldPage === 'home') {
                    let home = Home.getInstance();
                    home.homeSildeShowDestroy();
                }
                if (page === 'job-fair') {
                    body.classList.add('no-notice-bar');
                } else {
                    body.classList.remove('no-notice-bar');
                }



                // if (page !== 'home') {
                //     this.newContainer.ownerDocument.body.classList.remove('home');
                // } else {
                //     this.newContainer.ownerDocument.body.classList.add('home');
                // }

                // if (page !== 'news') {
                //     this.newContainer.ownerDocument.body.classList.remove('page-template-page-news');
                // } else {
                //     this.newContainer.ownerDocument.body.classList.add('page-template-page-news');
                // }

                // if (page !== 'getintouch') {
                //     this.newContainer.ownerDocument.body.classList.remove('page-template-page-getintouch');
                // } else {
                //     this.newContainer.ownerDocument.body.classList.add('page-template-page-getintouch');
                // }
                new LinkTarget();
            }

        });

        Barba.Pjax.getTransition = function () {
            return PageAnimation;
        };

    }

}