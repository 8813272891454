export default class Popup2021 {
    constructor() {
        this.promoPopup = document.querySelector('.promo-popup');
        this.btnClose = this.promoPopup.querySelector('.btn__close');
        this.init();
        this.registerEvents();
    }

    init(){
        setTimeout(() => {
            this.promoPopup.classList.add('active');
            setTimeout(() => this.promoPopup.classList.remove('faded'), 10);
        }, 0);
    }

    // Open on load


    registerEvents() {
        this.btnClose.addEventListener('click', () => {
            this.promoPopup.classList.add('faded');
            setTimeout(() => this.promoPopup.classList.remove('active'), 500);
        });

        this.promoPopup.addEventListener('click', () => {
            this.promoPopup.classList.add('faded');
            setTimeout(() => this.promoPopup.classList.remove('active'), 500);
        });
    }

}