/**
 * Accordion JS
 */

import { TweenLite } from "gsap";
import { getPosition } from "../helpers/helpers";

export default class Accordion {
  constructor(accordions) {
    this.container = accordions;
    this.accordionItemTitle = document.querySelectorAll(
      ".accordion-item__title"
    );
    this.init();
  }

  init() {
    this.registerEvents();
    let idName = location.hash.replace("#", "");
    if (idName) this.initOpen(idName);
    if (
      document
        .querySelector("body")
        .classList.contains("page-template-page-whiskey-river-bar")
    ) {
      this.setupElements();
    }
  }

  setupElements() {
    this.container.forEach((item, index) => {
      let firstTitle = item.querySelector(".accordion-item__title");
      this.toggle(firstTitle);
    });
  }

  registerEvents() {
    Array.from(this.accordionItemTitle).forEach((title) =>
      title.addEventListener("click", this.toggle)
    );
  }

  initOpen(id) {
    const ac = document.querySelector(`#${id}`);
    if (ac) {
      TweenLite.to(window, 1.5, {
        ease: Power3.easeInOut,
        scrollTo: { y: getPosition(ac).y, autoKill: false },
      });
      this.toggle(ac);
    }
  }
  toggle(e) {
    let parent;
    if (e.type === "click") {
      parent = e.currentTarget.parentNode;
    } else {
      parent = e.parentNode;
    }
    const content = parent.querySelector(".accordion-item__content");
    const wrapHeight = parent.querySelector(".wrap").clientHeight;
    parent.classList.toggle("open");

    if (content.style.height) {
      content.removeAttribute("style");
    } else {
      content.style.height = `${wrapHeight}px`;
    }
  }
}
