'use strict';

import 'babel-polyfill';
import Home from './modules/home';
import Excursion from './modules/excursion';
import Header from './modules/header';
// import SideContent from './modules/sideContent';
import LinkTarget from './modules/linkTarget';
import Getintouch from './modules/getintouch';
import Accordion from './modules/accordion';
import HoverSildeShow from './modules/hoversildeshow';
import PageTransition from './modules/pageTransition';
import Pilots from './modules/pilots';
import Medford from './modules/medford';
import General from './modules/general';
import AccordionHero from './modules/accordionHero';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

class App {

    constructor() {
        this.body = document.querySelector('body');
        this.accordions = [...document.querySelectorAll('.accordion')];
        this.barbaContainer = document.querySelector('.barba-container');
        this.pilots = document.querySelector('.pilots');
        this.pageTransition = null;
        this.routes();
    }

    /**
     * load Classes based on body CSS class
     */
    routes() {

        if (this.body.classList.contains('home')) {
            this.home = Home.getInstance();
            this.home.init();
        }


        if (this.body.classList.contains('page-template-page-medford')) {
            this.medford = new Medford();
        }

        if (this.body.classList.contains('page-template-page-excursion')) {
            this.excursion = new Excursion();
            this.general = new General();
        }

        if (this.body.classList.contains('page-template-page-getintouch')) {
            new Getintouch();
        }

        if (this.body.classList.contains('page-template-page-riverlodge')) {
            let hoverSildeShowFade = new HoverSildeShow("fade");
            hoverSildeShowFade.init();
            this.accordionHero = new AccordionHero();
        }

        if (this.pilots) {
            new Pilots();
        }
        if (this.accordions) {
            new Accordion(this.accordions);
        }
        new Header();
        new LinkTarget();

        if (this.barbaContainer) {
            this.pageTransition = new PageTransition();
            this.pageTransition.init();
        }

    }
}

window.App = new App();