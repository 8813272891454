import { NONAME } from "dns";

export default class Medford {


    constructor() {
        this.init();
    }

    init() {

        jQuery(function($) {
        
            let isMobile = true;
            if ( $('.hero__name').css('position') == 'fixed' ) {
                isMobile = false;
            }

            window.onbeforeunload = function () {
                window.scrollTo(0, 0);
            }

            $('.top-bar__x').click(() => {
                $('.top-bar').css('top', '');
            });

            let rect = document.getElementById('zooms').getBoundingClientRect();
            let wwidth = $(window).width() + "px";
            let wheight = $(window).height() + "px";

            let arg =  (rect.left+rect.right)/2 + 'px ' + (rect.top+rect.bottom)/2 + 'px';
            $('.hero').css('transform-origin', arg);

            let outlinesize = $('.hero__title--outline').css('font-size');
            $('.hero__title--outline').css('clip', 'rect(0px, ' + wwidth + ', ' + outlinesize + ', 0px');
            $('.hero__title').css('clip', 'rect(' + outlinesize + ', ' + wwidth + ', ' + outlinesize + ', 0px');
            $('.hero__title--outline > div').css('transform', 'translate(0, ' + outlinesize + ')');
            
            let tl = new TimelineMax();
            tl
            .to('.hero__name',1.5, {opacity:1, ease:Expo.easeOut}, 1)
            .to('.hero__icon',1.5, {opacity:1, ease:Expo.easeOut}, 1)
            .set('.hero__title--outline', {opacity: "1"}, 1)
            .set('.hero__title--1.hero__title--outline > div',{transform: "translate(0px, 0px)"}, 2.5)
            .set('.hero__title--2.hero__title--outline > div',{transform: "translate(0px, 0px)"}, 2.55)
            .set('.hero__title--3.hero__title--outline > div',{transform: "translate(0px, 0px)"}, 2.65)
            .set('.hero__backgrounds',{transform: "scale(1)"}, 1.5)
            .to('.arrows',2, {opacity:1, ease:Expo.easeOut}, 2.65)
            .to('.top-bar',1, {top:"0px", ease:Expo.easeOut}, 4);
            
            let height = $('.hero').height();
            let t2 = new TimelineMax();
            let doneintro = false;

            $('.info__text__up').click(() => window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            }));

            let slideIndex = 2;
            setInterval(() => {
                $('.hero__background').css('opacity', '0');
                $('.hero__background--' + slideIndex).css('opacity', '1');
                if( ++slideIndex > 6) {
                    slideIndex = 1;
                }
            }, 5000);

            let r = 54;
            let g = 67;
            let b = 49;

            function toDoOnScroll() {

              
                let arg =  (rect.left+rect.right)/2 + 'px ' + (rect.top+rect.bottom)/2 + 'px';
                $('.hero').css('transform-origin', arg);
            
                let offset = $(window).scrollTop();
                let opac = offset/height;
                if ( opac > 1 ) { opac = 1; }

                let darkness = (1-opac) * 255;
                let scale2 = Math.max(1, Math.pow(50*opac+1, 1.5));
                let brightness = 1-opac;

                let newr = r+(255-r)*brightness;
                let newg = g+(255-g)*brightness;
                let newb = b+(255-b)*brightness;

                if ( !isMobile ) {
                    $('.hero').css('transform', 'scale(' + scale2 + ')');
                }

                // $('.hero__filter').css('opacity', opac);
                $('.hero__name path').css('fill', 'rgb(' + darkness + ',' + darkness + ',' + darkness + ')');
                $('.hero__icon path').css('fill', 'rgb(' + newr + ',' + newg + ',' + newb + ')'); 
   
                // $('.hero__wrapper').css('opacity', 1-opac);
                // $('.hero__title, .arrows').css('transform', 'scale(' + scale + ')');
                // $('.arrows').css('top', -1 * offset + arrowinit);

                // console.log( oldScroll );
                // console.log( oldScroll < offset );
                // console.log( offset < height/2 );
                // if ( oldScroll < offset ) { // scroll down
                //     if ( offset < height/2 ) {
                //         window.scroll({
                //             top: height,
                //             behavior: 'smooth'
                //         })
                //     }
                // } else {
                //     if ( offset >= height/2 ) {
                //         window.scroll({
                //             top: 0,
                //             behavior: 'smooth'
                //         })
                //     }
                // }
                
                // oldScroll = offset;

                if ( offset >= height ) {
                    $('.info__map__inner').css('left', '0');
                    // $('.info__icon').css('opacity', '1');
                    $('.info__address').css('opacity','1');
                    $('.hero__filter').css('opacity', '1');
                    } else {
                    $('.info__map__inner').css('left', '100%');
                    // $('.info__icon').css('opacity', '0');
                    $('.info__address').css('opacity','0');    
                    $('.hero__filter').css('opacity', '0'); 
                }
            }
            
            $(window).scroll(function() {
            
                if (!doneintro) {
                    if ( !isMobile ) {
                        window.scrollTo(0,0);
                        $('body,html').css('overflow','hidden');
                        t2
                        .set('#medford-page .arrows .arrow path:nth-child(2)', {stroke:"white"})
                        .set('.hero__title--outline', {"-webkit-text-stroke-color":"white"})
                        .to('#medford-page .hero__title.hero__title--1',2, {clip:"path(0px," + wwidth + ", " + outlinesize + ",0px)",ease:Expo.easeOut}, 0)
                        .to('#medford-page .hero__title.hero__title--2',2, {clip:"path(0px," + wwidth + ", " + outlinesize + ",0px)",ease:Expo.easeOut}, 0.2)
                        .to('#medford-page .hero__title.hero__title--3',2, {clip:"path(0px," + wwidth + ", " + outlinesize + ",0px)",ease:Expo.easeOut}, 0.25)
                        .to('#medford-page .arrows .arrow',2, {top: "-35px",ease:Expo.easeOut}, 0.25)
                        .to('#medford-page .arrows .arrow path:first-child',2, {fill:"white", "fill-opacity":"0.4"}, 0.25)
                        .set('#medford-page .hero__title--outline > div:first-child',{visibility: "hidden"}, 1.75)
                        .set('body,html',{overflow:'visible'}, 0.75)
                        .call(function() {window.scrollTo({top:height, behavior: 'smooth'})}, null, null, 1.5)
                    } else {
                        t2
                        .set('#medford-page .arrows .arrow path:nth-child(2)', {stroke:"white"})
                        .set('.hero__title--outline', {"-webkit-text-stroke-color":"white"})
                        .to('#medford-page .hero__title.hero__title--1',2, {clip:"path(0px," + wwidth + ", " + outlinesize + ",0px)",ease:Expo.easeOut}, 0)
                        .to('#medford-page .hero__title.hero__title--2',2, {clip:"path(0px," + wwidth + ", " + outlinesize + ",0px)",ease:Expo.easeOut}, 0.2)
                        .to('#medford-page .hero__title.hero__title--3',2, {clip:"path(0px," + wwidth + ", " + outlinesize + ",0px)",ease:Expo.easeOut}, 0.25)
                        .to('#medford-page .arrows .arrow',2, {top: "-35px",ease:Expo.easeOut}, 0.25)
                        .to('#medford-page .arrows .arrow path:first-child',2, {fill:"white", "fill-opacity":"0.4"}, 0.25)
                        .set('#medford-page .hero__title--outline > div:first-child',{visibility: "hidden"}, 1.75)
                    }
                    doneintro = true;
                } else {
                    this.requestAnimationFrame(toDoOnScroll);
                }
            });
        });
    }
}
