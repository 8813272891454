import { NONAME } from "dns";

export default class General {


    constructor() {
        this.init();
    }

    init() {

    };
}

