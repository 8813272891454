/**
 * linkTarget JS
 */

'use strict';

import { TweenLite } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { getPosition } from '../helpers/helpers';

export default class LinkTarget {

	constructor() {
		this.navItems = [...document.querySelectorAll('.data-nav-target a')];
		this.events();
	}

	/**
	 * Event listeners
	 */
	events() {
		if (this.navItems) {
			this.navItems.forEach(item => {
				item.addEventListener('click', e => {
					e.preventDefault();
					const target = item.getAttribute('href');
					const tp = (target.split("#"))[1];
					if (tp) this.jumpLinkHandle(tp);
				});
			});
		}
		this.targetLinkto();
	}

	targetLinkto(){
		let idName = location.hash.replace('#', '');
		if (idName) this.jumpLinkHandle(idName);
	}

	jumpLinkHandle(targetPosition) {
		const section = document.querySelector(`[data-section="${targetPosition}"]`);
		const modalActive = document.querySelector('.modal-active');
		if (modalActive) {
			const sOverlay = modalActive.querySelector('.sidecontent-overlay');
			TweenLite.to(sOverlay, 1.5, { ease: Power3.easeInOut, scrollTo: { y:0, autoKill: false } });
		} else if (section) {
			TweenLite.to(window, 1.5, { ease: Power3.easeInOut, scrollTo: { y: getPosition(section).y, autoKill: false } });
		}
	}


}
